.menu {
  display: inline-flex;
  padding: 0;
  margin: 0;
}

.item {
  display: inline-block;
  margin-left: 1em;
  list-style: none;
}

@media (max-width: 768px) {
  .menu {
    width: 100%;
    justify-content: space-between;
    margin-left: 1em;
  }

  .item {
    margin-left: 0;
  }
}

@media (max-width: 380px) {
  .menu {
    margin-left: 1.4em;
  }

  /* the home icon is kinda redundant when space is SUPER tight */
  .item:first-of-type {
    display: none;
  }
}
